import Icon, { UpOutlined } from '@ant-design/icons'
import { Tabs, Typography, Row, Col, Button } from 'antd'
import React, { Component } from 'react'
import { ActiveIconPercorsi } from '../../assets/icons/ActiveIconPercorsi';
import { IconPercorsi } from '../../assets/icons/IconPercorsi';
import HeroBanner from '../../components/HeroBanner/HeroBanner';
import ContentTab from '../../components/Tab/ContentTab';
import i18n from '../../contexts/I18n';
import { getConfiguration, getPercorsiSommario, getPercorsoDettaglio } from '../../service/service';

const { TabPane } = Tabs;
const { Text, Title } = Typography;

class Percorsi extends Component {
    translate = i18n;

    constructor(props) {
        super(props)

        this.state = {
            tabPosition: "left",
            percorsi: {},
            currentDetail: {},
            activeKey: 1,
            configsMappa: {}
        }
    }

    changeTabPosition = () => {
        console.log("change")
        if (window.innerWidth < 992) {
            this.setState({
                tabPosition: "top"
            })
        } else {
            this.setState({
                tabPosition: "left"
            })
        }
    }


    componentDidMount() {
        window.addEventListener("resize", this.changeTabPosition)
        this.changeTabPosition();
        this.getPercorsiUtente();
        this.getConfigsMappa()
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.changeTabPosition)
    }

    getPercorsiUtente = () => {
        getPercorsiSommario(99999, 0)
            .then((response) => {
                this.getDettaglioPercorsoUtente(response.data, this.props.history.location.idPercorso ? this.props.history.location.idPercorso : response.data.list[0].idPercorso)
            })
            .catch((error) => {
                if (error.response?.status) {
                    Notification(
                        this.translate.t("consultori.errors.carosello.percorsi"),
                        null,
                        null,
                        "error"
                    );
                }
            });
    }

    getDettaglioPercorsoUtente = (responseSommario, activeKey) => {
        getPercorsoDettaglio(activeKey)
            .then((response) => {
                this.setState({
                    percorsi: responseSommario,
                    activeKey,
                    currentDetail: response.data
                });
            })
            .catch((error) => {
                if (error.response?.status) {
                    Notification(
                        this.translate.t("consultori.errors.carosello.percorsi"),
                        null,
                        null,
                        "error"
                    );
                }
            });
    }

    getConfigsMappa = () => {
        getConfiguration()
            .then((response) => {
                this.setState({
                    configsMappa: response.data.configs.mappa.testo,
                });
            })
            .catch((error) => {
                if (error.response?.status) {
                    Notification(
                        this.translate.t("consultori.errors.carosello.percorsi"),
                        null,
                        null,
                        "error"
                    );
                }
            });
    }

    renderContent = (activeKey, currentKey) => {
        if (currentKey.toString() === activeKey.toString()) {
            return <ContentTab configs={this.state.configsMappa} percorso={this.state.currentDetail} />
        } else {
            return null
        }
    }

    render() {
        return (
            <>
                <HeroBanner
                    isHomePage={false}
                    title={
                        this.translate.t("consultori.percorsi.title")
                    }
                    description={
                        <>
                            <Text>{this.translate.t("consultori.percorsi.description")}</Text>
                        </>
                    }
                />
                <Row justify="center">
                    <Col span={22}>
                        {this.state.percorsi?.list?.length > 0 &&
                            <Tabs tabPosition={this.state.tabPosition}
                                className="tab-percorso"
                                activeKey={this.state.activeKey ? this.state.activeKey?.toString() :
                                    this.state.percorsi?.list[0].idPercorso.toString()}
                                onChange={(key) => {
                                    this.setState({
                                        activeKey: key
                                    }, this.getDettaglioPercorsoUtente(this.state.percorsi, key));
                                }}
                                style={{ height: this.state.tabPosition === "left" ? 144 * this.state.percorsi?.totaleElementi : 'inherit' }}
                            >
                                {
                                    this.state.percorsi?.list.map((item, index) => {
                                        return <TabPane tab={
                                            <>
                                                <Row>
                                                    <Col span={22}>
                                                        <Title level={5} style={{ fontSize: 22, wordBreak: "break-word", whiteSpace: "break-spaces" }}>{item.title}</Title>
                                                    </Col>
                                                    <Col span={2} style={{ alignSelf: "center" }}>
                                                        <Icon className="icon-percorsi" component={IconPercorsi} />
                                                        <Icon className="active-icon-percorsi" component={ActiveIconPercorsi} />
                                                    </Col>
                                                </Row>

                                            </>} key={item.idPercorso} >
                                            {this.renderContent(
                                                this.state.activeKey ? this.state.activeKey :
                                                    this.state.percorsi?.list[0].idPercorso,
                                                item.idPercorso)}
                                        </TabPane>
                                    })
                                }
                            </Tabs>
                        }
                    </Col>
                </Row>
            </>
        )
    }
}

export default Percorsi