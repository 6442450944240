import './App.scss';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Row, Col, Spin, Result, ConfigProvider } from 'antd';
import Icon from '@ant-design/icons'
import Home from './pages/Home/Home';
import { gStore } from './store';
import ButtonGuidaUtente from './components/GuidaUtente/ButtonGuidaUtente/ButtonGuidaUtente';
import { Loader } from './assets/loader/loader';
import BreadcrumbManagement from './components/Breadcrumb/Breadcrumb';
import { globalMenuRoutes, globalRoutes } from './constants/menuAndRoutes';
import { useTranslation } from 'react-i18next';
import { Constants } from './constants/Constants';
import moment from 'moment';
import itIT from 'antd/lib/locale/it_IT';
import enUS from 'antd/lib/locale/en_US';
import { getDatiAnagrafici } from './service/service';
import NotCampaniaUser from './components/error/NotCampaniaUser';
import VisiteProgrammate from './pages/VisiteProgrammate/VisiteProgrammate';
import Documenti from './pages/Documenti/Documenti';
import Percorsi from './pages/Percorsi/Percorsi';
import axios from 'axios';
import Faq from './pages/Faq/Faq';

function App() {
  const history = useHistory();
  const [appState, setAppState] = useState(0);
  const { t } = useTranslation();
  const location = useLocation();
  const [stopTimer, setStopTimer] = useState(false)
  const [spinning, setSpinning] = useState(false)
  const locale = gStore.get('locale') ? gStore.get('locale') : 'it'
  let contatore = 0; let openedLoading = false;
  const init = () => {
    getDatiAnagrafici().then(result => {
      gStore.set({ user: result.data.data });
      gStore.set({ assistito: result.data.data });
      gStore.set({ correlationId: result.headers.correlationid })
      setAppState(1);
    }).catch(error => {
      if (error.response?.status === 401) {
        setAppState(3)
      }
      else {
        setAppState(2)
      }
    })
  }

  const renderGuidaUtente = () => {
    if (location.pathname !== "/faq")
      return <ButtonGuidaUtente />
    else
      return null
  }

  useEffect(() => {
    document.addEventListener("connected", (event) => {
      //Send events to portal
      if (window.parentIFrame) {
        window.parentIFrame.sendMessage(
          {
            type: "SET_HEADER_IMAGES",
            payload: {
              sx: `${Constants.VERTICALE_BASE_URL}/loghi_header_Consultori.png`,
              center: false,
              dx: false
            }
          }
        );

        const menu = [{
          type: "megamenu",
          title: t("consultori.site.name"),
          sections: [{ items: [] }],
        }];

        globalMenuRoutes.map((route) => {
          if (route.type === 'flat')
            menu[0].sections[0].items.push({ label: route.name, link: route.path, iframed: true })
          else
            menu[0].sections.push({
              sectionTitle: route.name,
              items: route.items.map(item => { return { label: item.name, link: item.path, iframed: true } })
            })
        }
        );

        window.parentIFrame.sendMessage(
          {
            type: "SET_MENU",
            payload: menu
          }
        );
      }
    });

    document.addEventListener("session-change", (event) => {
      moment.locale("it")
      const userDetail = event.detail;
      if (userDetail.session && userDetail.user) {
        gStore.set({
          portal_session: userDetail.session,
          portal_user: userDetail.user
        });
        setStopTimer(true)
        init();
      }
    });

    //Page change, manage the route change
    document.addEventListener("change-page", (event) => {
      const { detail } = event;
      const { page } = detail;
      history.replace({ pathname: page });
    });

    axios.interceptors.request.use((config) => {

      openLoading();

      console.log("Chiamata")
      return config;
    }, (error) => {
      console.log("errore");
    });

    axios.interceptors.response.use((response) => {
      closeLoading();

      return response
    }, (error) => {
      closeLoading();
      return error;
    });
  }, [])

  useEffect(() => {
    let id;

    if (!stopTimer) {
      id = setInterval(() => { setAppState(2) }, 30000);
    }

    return () => clearInterval(id);
  }, [stopTimer]);

  const openLoading = () => {
    contatore = contatore + 1;
    if (!openedLoading) {
      setSpinning(true);
      openedLoading = true;
    }
  }

  const closeLoading = () => {
    contatore = contatore - 1;
    if (contatore === 0) {
      setSpinning(false);
      openedLoading = false;
    }
  }

  const renderComponentByState = function () {
    switch (appState) {
      case 0:
        return <>
          <Row gutter={[0, 100]}> <Col span={24} /> </Row>
          <Row justify='center' style={{ position: 'absolute', top: '34%', left: '34vw', right: '34vw' }}>
            <Col>
              <Spin
                indicator={<><Icon spin component={Loader} />
                  <div className="ant-spin-text">
                    {t("consultori.loading.title")}
                    <div className="dot-elastic" />
                  </div>
                </>}
                tip={""}
              />
            </Col>
          </Row>
        </>;
      case 1:
        return (
          <ConfigProvider locale={locale === 'it' ? itIT : enUS}>
            <Spin className="spin-api"
              indicator={<><Icon spin component={Loader} />
                <div className="ant-spin-text">
                  {t("consultori.loading.title")}
                  <div className="dot-elastic" />
                </div>
              </>}
              tip={""}
              spinning={spinning}
            >
              <div className="App ant-layout-content site-layout-background" style={{ paddingBottom: 40 }}>
                <BreadcrumbManagement routes={globalRoutes} />
                <Switch>
                  <Route path="/faq" component={Faq} />
                  <Route path="/percorsi" component={Percorsi} />
                  {/*<Route path="/documenti" component={Documenti} />*/}
                  <Route path="/visite-programmate" component={VisiteProgrammate} />
                  <Route path="/" component={Home} />
                </Switch>
                <div className="button-guida-utente">
                  {renderGuidaUtente()}
                </div>
              </div>
            </Spin>
          </ConfigProvider>
        );
      case 2:
        return (
          <Result status="warning" style={{ position: 'absolute', top: '32%', left: '34vw', right: '34vw', padding: 'unset' }}
            title={t('consultori.service.notavailable.title')}
            subTitle={t('consultori.service.notavailable.description')}
          />
        );
      case 3:
        return <div style={{ position: 'absolute', top: '12%', left: '20vw', right: '20vw' }}><NotCampaniaUser /></div>
    }
  }

  return renderComponentByState();
}
export default App;
