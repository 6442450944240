import { notification, Space, Typography } from 'antd';
import React from 'react';
import { Info } from '../../assets/icons/InfoIcon';
import Icon from '@ant-design/icons'
import i18n from '../../contexts/I18n';
const { Text, Link } = Typography;
export const Notification = (description, link, props, type = 'error') => {
    const translate = i18n;
    switch (type) {
        case 'error':
            notification.error({
                message: <Text strong>{translate.t('consultori.errors.title')}</Text>,
                description:
                    <Text style={{ fontSize: 14 }}>
                        {description}
                    </Text>,
                duration: 5
            });
            break;
        default:
            break;
    }

}


