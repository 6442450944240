import React, { Component } from 'react'
import i18n from '../../contexts/I18n'
import CardPercorso from '../Cards/CardPercorso/CardPercorso'

export default class ContentTab extends Component {
    translate = i18n;
    ref = React.createRef();

    constructor(props) {
        super(props)
    }

    generateMappa = (refToDiv, nodes, edges, configs) => {
        var container = refToDiv
        var data = {
            nodes: nodes ? JSON.parse(nodes) : null,
            edges: edges ? JSON.parse(edges) : null
        }
        var options = JSON.parse(configs)
        if (container && options && data.nodes && data.edges) {
            var network = new window.vis.Network(container, data, options);
        }

    }

    componentDidMount() {
        this.generateMappa(this.ref.current, this.props.percorso?.nodes, this.props.percorso?.edges, this.props.configs)
    }

    componentDidUpdate() {
        this.generateMappa(this.ref.current, this.props.percorso?.nodes, this.props.percorso?.edges, this.props.configs)
    }

    render() {
        return (
            <>
                <div id="mappa" ref={this.ref} style={{ height: 720 }}></div>
                <CardPercorso title={this.translate.t("consultori.percorsi.cosa.e")} description={this.props.percorso?.whatIsThat} />
                <CardPercorso title={this.translate.t("consultori.percorsi.rivolta.a")} description={this.props.percorso?.addressedTo} />
                <CardPercorso title={this.translate.t("consultori.percorsi.cosa.occorre")} description={this.props.percorso?.whatIsNeeded} />
            </>
        )
    }
}
