import React, { Component } from 'react'
import { Row, Col, Typography, Button, Space } from 'antd';
import { Logo } from '../../assets/icons/Logo';
import Icon from '@ant-design/icons'

const { Text, Title } = Typography;
export default class HeroBanner extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <Row
                gutter={[0, 100]}
                align='middle'
                style={{ backgroundColor: '#F4F5F6', textAlign: 'left', marginBottom: 50 }}
                justify='center'
            >
                <Col span={22} style={this.props.isHomePage ? { paddingTop: 50, paddingBottom: 50 } : { paddingBottom: 50 }}>
                    <Row align='middle'>
                        {
                            this.props.isHomePage ?
                                <><Col xl={7}>
                                    <Icon className="logo" component={Logo} />
                                </Col>
                                    <Col sm={1} md={1} lg={1} xl={2} xxl={1} />
                                </>
                                : null
                        }

                        <Col xl={this.props.isHomePage ? 15 : 22}>
                            <Space
                                direction='vertical'
                                size='large'
                                align='start'
                            >
                                <Space
                                    direction='vertical'
                                    align='start'
                                >
                                    <Title level={this.props.isHomePage ? 1 : 3}>
                                        {this.props.title}
                                    </Title>
                                    <Row justify="start">
                                        <Col span={24}>
                                            {this.props.description}
                                        </Col>
                                    </Row>
                                </Space>
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

