import React, { Component } from 'react'
import { Row, Col, Typography } from "antd";

import HeroBanner from "../../components/HeroBanner/HeroBanner";
import CTable from "../../components/Tables/CTable";
import SearchForm from '../../components/Forms/SearchForm';
import CardBox from '../../components/Cards/CardBox/CardBox';
import i18n from '../../contexts/I18n';
import { getVisite } from '../../service/service';

const { Text } = Typography;
export default class VisiteProgrammate extends Component {
    translate = i18n;
    constructor(props) {
        super(props)

        this.state = {
            visite: {},
            loadingTableVisite: false,
            currentPage: 0,
            currentSearch: ""
        }
    }

    changePage = (page) => {
        this.setState({ currentPage: page }, () => this.getVisiteProgrammate());
    }

    search = (search) => {
        this.setState({ currentSearch: search, currentPage: 0 }, () => this.getVisiteProgrammate());
    }

    getVisiteProgrammate = () => {
        this.setState({
            loadingTableVisite: true,
        });

        getVisite(this.state.currentSearch, 5, this.state.currentPage)
            .then((response) => {
                this.setState({
                    visite: response.data,
                    loadingTableVisite: false,
                });
            })
            .catch((error) => {
                this.setState({
                    loadingTableVisite: false,
                });
                if (error.response?.status) {
                    Notification(
                        this.translate.t("consultori.errors.visite"),
                        null,
                        null,
                        "error"
                    );
                }
            });
    };

    componentDidMount() {
        this.getVisiteProgrammate();
    }

    render() {
        const columnsVisite = [
            {
                title: this.translate.t("consultori.table.visite.colonna.nome"),
                dataIndex: "name",
                key: "name",
                width: "50%",
                render: text => <Text title={text}>{text}</Text>
            },
            {
                title: this.translate.t("consultori.table.visite.colonna.luogo"),
                dataIndex: "place",
                key: "place",
                width: '20%',
                render: text => <Text title={text}>{text}</Text>
            },
            {
                title: this.translate.t("consultori.table.visite.colonna.data"),
                dataIndex: "data",
                key: "data",
                render: text => <Text title={text}>{text}</Text>
            },
            {
                title: this.translate.t("consultori.table.visite.colonna.scadenza"),
                dataIndex: "deadline",
                key: "deadline",
                render: text => <Text title={text}>{text}</Text>
            },
        ];

        return (
            <>
                <HeroBanner
                    isHomePage={false}
                    title={
                        this.translate.t("consultori.table.visite.title")
                    }
                    description={
                        <>
                            <Text>{this.translate.t("consultori.visite.description-1")}</Text>
                            <br />
                            <Text>{this.translate.t("consultori.visite.description-2")}</Text>
                        </>
                    }
                />


                <Row justify="center">
                    <Col span={24}>
                        <Row justify="center">
                            <Col span={22}>
                                <CardBox>
                                    <SearchForm search={this.search.bind(this)} />
                                </CardBox>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={22}>
                                <CTable
                                    //loading={this.state.loadingTableVisite}
                                    lista={this.state.visite?.list}
                                    columns={columnsVisite}
                                    emptyMessage={this.translate.t("consultori.table.visite.empty")}
                                    showPagination={true}
                                    pageSize={5}
                                    totalElement={this.state.visite?.totaleElementi}
                                    changePage={this.changePage.bind(this)}
                                    currentPage={this.state.currentPage + 1}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}