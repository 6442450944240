import { Card } from 'antd'
import React, { Component } from 'react'

export default class CardPercorso extends Component {
    render() {
        return (
            this.props.description ?
                <Card title={this.props.title} className="card-percorsi" >
                    {this.props.description}
                </Card> : null
        )
    }
}
