import { Button, Space } from 'antd';

import React, { Component } from 'react'
import ModalGuidaUtente from '../ModalGuidaUtente/ModalGuidaUtente';
import Icon from '@ant-design/icons'
import { GuidaUtente } from '../../../assets/icons/ButtonGuidaUtenteIcon';
import i18n from '../../../contexts/I18n';
class ButtonGuidaUtente extends Component {
    translate = i18n;
    constructor(props) {
        super(props)

        this.state = {
            show: false,
            guidaUtenteLabel: false,
            guidaUtenteVisible: false
        }
    }

    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    render() {
        return (
            <>
                <Button
                    type='primary'
                    size='large'
                    style={{
                        display: 'flex',
                        justifyContent: !this.state.guidaUtenteLabel ? 'center' : 'space-around',
                        alignItems: 'center',
                        alignContent: 'center',
                        width: !this.state.guidaUtenteLabel ? 66 : 207,
                        height: 66,
                        padding: !this.state.guidaUtenteLabel ? null : 0
                    }}
                    onMouseEnter={() => this.setState({
                        guidaUtenteLabel: !this.state.guidaUtenteLabel
                    })}
                    onMouseLeave={() => this.setState({
                        guidaUtenteLabel: !this.state.guidaUtenteLabel
                    })}
                    onClick={this.showModal}
                >
                    <Space>
                        {this.state.guidaUtenteLabel && this.translate.t("consultori.guida.utente.button.title")}
                        <Icon
                            component={
                                GuidaUtente
                            }
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                alignContent: 'center'
                            }}
                        />
                    </Space>
                </Button>

                <ModalGuidaUtente
                    visible={this.state.show}
                    hideModal={this.hideModal}
                    guideType={this.props.guideType}
                />

            </>
        )
    }
}
export default ButtonGuidaUtente






















