import React, { Component } from 'react'

import firstSlideHomepage from '../../../assets/guida/home/first.svg'
import secondSlideHomepage from '../../../assets/guida/home/second.svg'
import firstSlideAssistiti from '../../../assets/guida/assistiti/first.svg'
import secondSlideAssistiti from '../../../assets/guida/assistiti/second.svg'
import firstSlideCalendarioVaccinazioni from '../../../assets/guida/calendarioVaccinazioni/first.svg'
import secondSlideCalendarioVaccinazioni from '../../../assets/guida/calendarioVaccinazioni/second.svg'
import thirdSlideCalendarioVaccinazioni from '../../../assets/guida/calendarioVaccinazioni/third.svg'
import fourthSlideCalendarioVaccinazioni from '../../../assets/guida/calendarioVaccinazioni/fourth.svg'
import firstSlideCorredovaccinale from '../../../assets/guida/corredoVaccinale/first.svg'
import secondSlideCorredovaccinale from '../../../assets/guida/corredoVaccinale/second.svg'
import thirdSlideCorredovaccinale from '../../../assets/guida/corredoVaccinale/third.svg'
import fourthSlideCorredovaccinale from '../../../assets/guida/corredoVaccinale/fourth.svg'

import Icon from '@ant-design/icons/lib/components/Icon'
import { LeftArrow } from '../../../assets/icons/LeftArrowIcon'
import { RightArrow } from '../../../assets/icons/RightArrowIcon'
import { CloseOutlined } from '@ant-design/icons'
import { Row, Col, Button, Space, Carousel, Image, Modal } from 'antd';


import './ModalGuidaUtente.css'

import i18n from '../../../contexts/I18n';
class ModalGuidaUtente extends Component {
    translate = i18n;
    carouselRef = React.createRef();
    constructor(props) {
        super(props)

        this.state = {
            dotsCounter: {
                active: 0,
                length: 0
            },
            imgArray: []
        }
    }

    handlePrev = () => {
        this.carouselRef.current.prev();

        /*if (this.state.dotsCounter.active === 0)
            this.setState({
                dotsCounter: {
                    length: this.carouselRef.current.innerSlider.state.slideCount - 1,
                    active: this.carouselRef.current.innerSlider.state.slideCount - 1
                }
            })
        else
            this.setState({
                dotsCounter: {
                    length: this.carouselRef.current.innerSlider.state.slideCount - 1,
                    active: this.state.dotsCounter.active - 1
                }
            })*/
    }

    handleNext = () => {
        this.carouselRef.current.next();
        /*if (this.state.dotsCounter.active === this.carouselRef.current.innerSlider.state.slideCount - 1)
            this.setState({
                dotsCounter: {
                    length: this.carouselRef.current.innerSlider.state.slideCount - 1,
                    active: 0
                }
            })
        else {
            this.setState({
                dotsCounter: {
                    length: this.carouselRef.current.innerSlider.state.slideCount - 1,
                    active: this.state.dotsCounter.active + 1
                }
            })
        }*/
    }

    handleDots = (dots) => (
        <Row justify='center' style={{ position: 'relative', top: '100px' }}>
            <Space size={50}>
                <Button
                    style={{ backgroundColor: 'transparent', border: 0 }}
                    onClick={this.handlePrev}
                >
                    <Icon component={LeftArrow} />
                </Button>
                <Space size={20} className="space-dots">
                    {dots}
                </Space>
                <Button
                    style={{ backgroundColor: 'transparent', border: 0 }}
                    onClick={this.handleNext}
                >
                    <Icon component={RightArrow} />
                </Button>
            </Space>
        </Row>
    );

    render() {
        let imgArray = [];
        switch (window.location.pathname) {
            case "/home":
                imgArray = [
                    <Image height='100%' width='100%' preview={false} src={firstSlideHomepage} />,
                    <Image height='100%' width='100%' preview={false} src={secondSlideHomepage} />
                ]
                break;
            case "/gestione-altri-assistiti":
                imgArray = [
                    <Image height='100%' width='100%' preview={false} src={firstSlideAssistiti} />,
                    <Image height='100%' width='100%' preview={false} src={secondSlideAssistiti} />
                ]


                break;
            case "/calendario-vaccinazioni":
                imgArray = [

                    <Image height='100%' width='100%' preview={false} src={firstSlideCalendarioVaccinazioni} />,
                    <Image height='100%' width='100%' preview={false} src={secondSlideCalendarioVaccinazioni} />,
                    <Image height='100%' width='100%' preview={false} src={thirdSlideCalendarioVaccinazioni} />,
                    <Image height='100%' width='100%' preview={false} src={fourthSlideCalendarioVaccinazioni} />
                ]
                break;
            case "/corredo-vaccinale":
                imgArray = [
                    <Image height='100%' width='100%' preview={false} src={firstSlideCorredovaccinale} />,
                    <Image height='100%' width='100%' preview={false} src={secondSlideCorredovaccinale} />,
                    <Image height='100%' width='100%' preview={false} src={thirdSlideCorredovaccinale} />,
                    <Image height='100%' width='100%' preview={false} src={fourthSlideCorredovaccinale} />
                ]

                break;
            default:
                imgArray = [
                    <Image height='100%' width='100%' preview={false} src={firstSlideHomepage} />,
                    <Image height='100%' width='100%' preview={false} src={secondSlideHomepage} />
                ]
                break;
        }

        return (
            <>
                <Modal
                    width='100vw'
                    className='no-shadow-modal'
                    style={{ top: 0 }}
                    bodyStyle={{ height: '100%', padding: 0, backgroundColor: 'rgba(0, 38, 77)' }}
                    maskClosable={false}
                    maskStyle={{ backgroundColor: 'rgba(0, 38, 77)', zIndex: 1011 }}
                    visible={this.props.visible}
                    footer={null}
                    onCancel={this.props.hideModal}
                    destroyOnClose
                    closeIcon={null}
                    wrapClassName='modal-utente'
                >
                    <div>
                        <Row gutter={[0, 50]}>
                            <Col>
                                <Button
                                    className='btn-guida-utente-outlined btn-resizable'
                                    onClick={this.props.hideModal}
                                >
                                    <Space>
                                        {this.translate.t("consultori.guida.utente.button.modal")}
                                        <CloseOutlined />
                                    </Space>
                                </Button>
                            </Col>
                        </Row>
                        {imgArray.length > 0 &&
                            <Carousel
                                ref={this.carouselRef}
                                style={{ position: 'relative', top: '12vh' }}
                                appendDots={this.handleDots}
                            >
                                {imgArray.map((image, index) => <div className="image-carousel" key={index}>{image}</div>)}
                            </Carousel>
                        }
                    </div>
                </Modal>
            </>
        )
    }
}

export default ModalGuidaUtente









