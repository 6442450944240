import React, { Component } from 'react'
import { Form, Input, Button, Typography } from 'antd';
import { Erase } from '../../assets/icons/EraseIcon';
import Icon from '@ant-design/icons'
import i18n from '../../contexts/I18n';
const { Text } = Typography;
class SearchForm extends Component {
    translate = i18n;
    formRef = React.createRef();
    constructor(props) {
        super(props)

        this.state = {
            style: {
                display: 'none'
            }
        }
    }

    showSuffix = () => {
        if (this.formRef.current.getFieldValue("search") !== "") {
            this.setState({
                style: {
                    display: 'unset',
                    paddingLeft: '12px',
                    borderLeft: '1px solid #AF1857'
                }
            })
        } else {
            this.setState({
                style: {
                    display: 'none'
                }
            })
        }
    }

    eraseInput = () => {
        this.formRef.current.setFieldsValue({ search: "" })
        this.setState({
            style: {
                display: 'none'
            }
        })
    }
    onFinish = (values) => {
        this.props.search(values.search)
    }

    render() {
        return (
            <Form ref={this.formRef} name="formSearch" onFinish={this.onFinish} style={{ display: 'inline-flex', alignItems: 'end' }} >
                <Form.Item
                    name="search"
                    label={<Text strong>{this.translate.t("consultori.form.filtri.label.cerca")}</Text>}
                    labelCol={{ span: 24 }}
                >
                    <Input autoComplete="off" onChange={this.showSuffix} suffix={<Icon onClick={this.eraseInput} style={this.state.style} component={Erase} />} placeholder={this.translate.t("consultori.form.filtri.placeholder.visita")} style={{ height: '40px' }} />
                </Form.Item>

                <Form.Item shouldUpdate>
                    {() => (
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="btn-search"

                        >
                            {this.translate.t("consultori.form.filtri.button.cerca")}
                        </Button>
                    )}
                </Form.Item>
            </Form>
        )
    }
}
export default SearchForm






















