export const ActiveIconPercorsi = () => <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M-0.000488281 16C-0.000488281 24.8357 7.1638 32 15.9995 32C24.8352 32 31.9995 24.8357 31.9995 16C31.9995 7.16425 24.8352 -3.43323e-05 15.9995 -3.43323e-05C7.1638 -3.43323e-05 -0.000488281 7.16425 -0.000488281 16ZM12.6317 9.41068L21.4174 15.7678C21.4539 15.7941 21.4836 15.8287 21.5041 15.8688C21.5246 15.9088 21.5353 15.9532 21.5353 15.9982C21.5353 16.0432 21.5246 16.0875 21.5041 16.1276C21.4836 16.1676 21.4539 16.2022 21.4174 16.2285L12.6317 22.5857C12.4424 22.7214 12.1781 22.5857 12.1781 22.3535V20.6785C12.1781 20.3143 12.3531 19.9678 12.6495 19.7535L17.8424 16L12.6495 12.2428C12.3531 12.0285 12.1781 11.6857 12.1781 11.3178V9.64282C12.1781 9.41068 12.4424 9.27497 12.6317 9.41068Z" fill="white" />
</svg>
















