import { Button, Space, Table, Empty } from 'antd';
import React, { Component } from 'react';

import i18n from '../../contexts/I18n';

class CTable extends Component {
    translate = i18n;
    render() {
        const api = {
            pagination: this.props.showPagination ? {
                pageSize: this.props.pageSize ? this.props.pageSize : 5,
                onChange: (page, pageSize) => {
                    this.props.changePage(page - 1);
                },
                hideOnSinglePage: true,
                position: ["bottomCenter"],
                size: "32px",
                total: this.props.totalElement ? this.props.totalElement : 0,
                current: this.props.currentPage
            } : false,
            rowClassName: (record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark',
            size: 'middle',
            rowKey: record => record.id,
            loading: {
                spinning: this.props.loading ? this.props.loading : false,
                indicator: <div className="dot-spin" />,
                tip: null
            },
            scroll: { x: 'auto' },
            locale: {
                emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={this.props.emptyMessage} />
            }
        };
        return (
            <Table className="table-custom consultori" dataSource={this.props.lista} columns={this.props.columns} {...api} />
        )
    }
}
export default CTable