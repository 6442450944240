import React, { Component } from "react";
import { Row, Col, Typography, Button, Space, Carousel, Image } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { gStore } from "../../store";
import { Notification } from "../../components/Notification/Notification";
import i18n from "../../contexts/I18n";
import { downloadDocument, getDocumenti, getPercorsi, getVisite } from "../../service/service";
import HeroBanner from "../../components/HeroBanner/HeroBanner";
import CTable from "../../components/Tables/CTable";
import HeadingTable from "../../components/Heading/HeadingTable";
import CarouselHomePage from "../../components/Carousel/CarouselHomePage";
import { Link } from "react-router-dom";

const { Text, Title, Paragraph } = Typography;
class Home extends Component {
  translate = i18n;
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      visite: {},
      documenti: {},
      percorsi: {},
      loadingTableVisite: false,
      loadingTableDocumenti: false,
      loadingDownload: {}
    }
  }

  user = gStore.get("user");

  getVisiteProgrammate = () => {
    this.setState({
      loadingTableVisite: true,
    });
    getVisite("", 3, 0)
      .then((response) => {
        this.setState({
          visite: response.data,
          loadingTableVisite: false,
        });
      })
      .catch((error) => {
        this.setState({
          loadingTableVisite: false,
        });
        if (error.response?.status) {
          Notification(
            this.translate.t("consultori.errors.visite"),
            null,
            null,
            "error"
          );
        }
      });
  };

  getUltimiDocumenti = () => {
    this.setState({
      loadingTableDocumenti: true,
    });
    getDocumenti("", "", 3, 0)
      .then((response) => {
        this.setState({
          documenti: response.data,
          loadingTableDocumenti: false,
        });
      })
      .catch((error) => {
        this.setState({
          loadingTableDocumenti: false,
        });
        if (error.response?.status) {
          Notification(
            this.translate.t("consultori.errors.documenti"),
            null,
            null,
            "error"
          );
        }
      });
  };

  downloadPdf = (id, nome, index) => {
    let blob;
    let pdf;
    this.setState({
      loadingDownload: {
        [index]: true
      }
    })

    downloadDocument(id).then(result => {
      blob = new Blob([result.data], { type: 'application/pdf' });
      pdf = URL.createObjectURL(blob);
      this.setState({
        loadingDownload: {
          [index]: false
        }
      })
      //window.open(pdf)
      const link = document.createElement("a");
      link.href = pdf;
      let fileName = this.translate.t('consultori.fileNames.documento') + nome;
      const headerDisposition = result.headers["content-disposition"];
      if (headerDisposition) {
        fileName = headerDisposition.split("filename=")[1] && headerDisposition.split("filename=")[1] !== "null" ? headerDisposition.split("filename=")[1] : fileName;
      }
      link.setAttribute("download", fileName);
      link.click();
    }).catch(error => {
      this.setState({
        loadingDownload: {
          ...this.state.loadingDownload,
          [index]: false
        }
      })
      Notification(this.translate.t('consultori.errors.pdf'), null, null, "error")
    })
  }

  getPercorsiUtente = () => {
    getPercorsi(99999, 0)
      .then((response) => {
        this.setState({
          percorsi: response.data,
        });
      })
      .catch((error) => {
        if (error.response?.status) {
          Notification(
            this.translate.t("consultori.errors.carosello.percorsi"),
            null,
            null,
            "error"
          );
        }
      });
  }

  componentDidMount() {
    this.getVisiteProgrammate();
    // this.getUltimiDocumenti();
    this.getPercorsiUtente();
  }

  render() {
    const columnsVisite = [
      {
        title: this.translate.t("consultori.table.visite.colonna.nome"),
        dataIndex: "name",
        key: "name",
        width: "40%",
        render: text => <Text title={text}>{text}</Text>
      },
      {
        title: this.translate.t("consultori.table.visite.colonna.data"),
        dataIndex: "data",
        key: "data",
        width: "30%",
        render: text => <Text title={text}>{text}</Text>
      },
      {
        title: this.translate.t("consultori.table.visite.colonna.scadenza"),
        dataIndex: "deadline",
        key: "deadline",
        render: text => <Text title={text}>{text}</Text>
      },
    ];

    const columnsDocumenti = [
      {
        title: this.translate.t("consultori.table.documenti.colonna.reparto"),
        dataIndex: "department",
        key: "department",
        render: text => <Text title={text}>{text}</Text>
      },
      {
        title: this.translate.t("consultori.table.documenti.colonna.nome"),
        dataIndex: "name",
        key: "name",
        width: "35%",
        render: text => <Text title={text}>{text}</Text>
      },
      {
        title: this.translate.t("consultori.table.documenti.colonna.data"),
        dataIndex: "data",
        key: "data",
        render: text => <Text title={text}>{text}</Text>
      },
      {
        title: this.translate.t("consultori.table.documenti.colonna.azioni"),
        align: "center",
        className: "col-azioni",
        render: (text, record, index) => (
          <Space direction="vertical" size={0}>
            <Button
              className="btn-custom"
              danger
              icon={<>{this.translate.t("consultori.table.documenti.scarica")} <DownloadOutlined /></>}
              onClick={() =>
                this.downloadPdf(record?.id, record?.name, index)
              }
              //loading={this.state.loadingDownload[index]}
              style={{ fontSize: '14px', width: '75px', padding: '5px' }}
            />
          </Space>
        ),
      },
    ];

    return (
      <React.Fragment>
        <HeroBanner
          isHomePage={true}
          title={
            this.translate.t("consultori.benvenuto") +
            " " +
            this.user?.nome +
            " " +
            this.user?.cognome
          }
          description={
            <>
              <Text>{this.translate.t("consultori.home.title")}</Text>
            </>
          }
        />
        <CarouselHomePage percorsi={this.state.percorsi} />

        <Row justify="center">
          <Col span={24} lg={24}>
            <Row justify="center" align="bottom">
              <Col span={22}>
                <HeadingTable
                  title={this.translate.t("consultori.table.visite.title")}
                  link={"visite-programmate"}
                  linkName={this.translate.t("consultori.table.visite.vedi.tutte")}
                />
              </Col>
            </Row>
            <Row justify="center">
              <Col span={22}>
                <CTable
                  //loading={this.state.loadingTableVisite}
                  lista={this.state.visite?.list}
                  columns={columnsVisite}
                  emptyMessage={this.translate.t("consultori.table.visite.empty")}
                  showPagination={false}
                />
              </Col>
            </Row>
          </Col>
          {/*<Col span={24} lg={12}>*/}
          {/*  <Row justify="center" align="bottom">*/}
          {/*    <Col span={22}>*/}
          {/*      <HeadingTable*/}
          {/*        title={this.translate.t("consultori.table.documenti.title")}*/}
          {/*        link={"documenti"}*/}
          {/*        linkName={this.translate.t("consultori.table.documenti.vedi.tutti")}*/}
          {/*      />*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*  <Row justify="center">*/}
          {/*    <Col span={22}>*/}
          {/*      <CTable*/}
          {/*        //loading={this.state.loadingTableDocumenti}*/}
          {/*        lista={this.state.documenti?.list}*/}
          {/*        columns={columnsDocumenti}*/}
          {/*        emptyMessage={this.translate.t("consultori.table.documenti.empty")}*/}
          {/*        showPagination={false}*/}
          {/*      />*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*</Col>*/}
        </Row>
      </React.Fragment>
    );
  }
}
export default Home;
