import { Breadcrumb, Col, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

const { Text } = Typography;

export default function BreadcrumbManagement({ routes }) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [breadcrumbPages, setBreadcrumbPages] = useState([]);
  const _location = useLocation();

  useEffect(() => {
    let pages = [];
    routes.map((route) => {
      if (route.breadcrumb)
        pages.push(route.path);
    });
    setBreadcrumbPages(pages);
  }, []);

  return (
    < div key={'breadcrumb-management'} >
      {
        breadcrumbPages.includes(_location.pathname.toString()) &&
        <>
          <Row style={{ backgroundColor: '#F4F5F6', minHeight: 20 }}> <Col span={24} /> </Row>
          <Row style={{ backgroundColor: '#F4F5F6' }}>
            <Col span={1} />
            <Col>
              <Breadcrumb separator=''>
                {routes.map((route) => {
                  if (route.path === _location.pathname)
                    return (
                      route.breadcrumb?.map((breadcumbItem, index) => (
                        <>
                          {index === 0 &&
                            <>
                              <Breadcrumb.Item onClick={() => history.replace({ pathname: "/" })}>
                                <Text
                                  underline strong
                                  style={{ color: '#AF1857', fontSize: 12, cursor: 'pointer' }}
                                >
                                  {"HOME"}
                                </Text>
                              </Breadcrumb.Item>
                              <Breadcrumb.Separator>
                                <Text strong style={{ color: '#AF1857', fontSize: 12 }}>
                                  |
                                </Text>
                              </Breadcrumb.Separator>
                            </>
                          }
                          <Breadcrumb.Item onClick={() => breadcumbItem.path ? history.replace({ pathname: breadcumbItem.path }) : null}>
                            <Text
                              strong
                              style={{
                                color: (index < route.breadcrumb.length - 1) ? '#896F17' : '#5E7887',
                                fontSize: 12, cursor: breadcumbItem.path ? 'pointer' : 'auto'
                              }}
                            >{index < route.breadcrumb.length - 1 ? <u>{breadcumbItem.label}</u> : breadcumbItem.label}
                            </Text>
                          </Breadcrumb.Item>
                          {index < route.breadcrumb.length - 1 &&
                            <Breadcrumb.Separator>
                              <Text strong style={{ color: '#896F17', fontSize: 12 }}>
                                |
                              </Text>
                            </Breadcrumb.Separator>
                          }
                        </>
                      ))
                    )
                })}
              </Breadcrumb>
            </Col>
          </Row>
          <Row style={{ backgroundColor: '#F4F5F6', minHeight: 20 }}> <Col span={24} /> </Row>
        </>
      }
    </div >
  );

}














