export const Logo = () => <svg width="283" height="207" viewBox="0 0 283 207" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_7_622)">
<path opacity="0.1" d="M164.053 31.1578C145.952 30.5287 128.698 24.6847 112.247 18.2922C95.7969 11.8997 79.4921 4.81567 61.8145 1.42834C50.4489 -0.752759 37.4507 -1.05951 28.2948 5.03404C19.482 10.8963 16.6354 21.0166 15.1042 30.4065C13.9525 37.4724 13.2844 44.9073 16.4326 51.5234C18.6241 56.1144 22.5131 59.9748 25.2038 64.3734C34.5625 79.6775 27.9464 98.5506 17.8026 113.506C13.0453 120.526 7.52622 127.204 3.85554 134.667C0.184848 142.131 -1.51531 150.682 1.69784 158.293C4.8824 165.832 12.4681 171.505 20.6882 175.49C37.3831 183.577 57.0546 185.891 76.2376 187.217C118.7 190.12 161.391 188.863 203.965 187.604C219.722 187.136 235.549 186.666 251.048 184.225C259.655 182.87 268.543 180.723 274.792 175.539C282.724 168.957 284.692 157.815 279.376 149.561C270.459 135.72 245.807 132.281 239.573 117.427C236.144 109.251 239.666 100.142 244.652 92.5589C255.345 76.2925 273.272 62.0208 274.215 43.4255C274.865 30.6535 266.247 17.8659 252.922 11.8217C238.956 5.48118 219.59 6.28187 209.287 16.761C198.678 27.5573 180.033 31.7115 164.053 31.1578Z" fill="#AF1857"/>
<path d="M262.422 70.2012L244.613 70.375C243.777 66.2542 240.964 55.442 243.497 52.4928C243.613 51.3828 244.152 50.3601 245.002 49.6376C245.853 48.9151 246.949 48.5485 248.063 48.614C250.823 44.2934 255.42 44.9421 258.528 47.6405C261.655 48.7196 262.834 50.6365 262.422 53.238C264.738 55.2652 265.165 57.7315 264.125 60.5391L262.422 70.2012Z" fill="#2F2E41"/>
<path d="M260.707 81.1716L247.859 79.3925L245.29 75.6373C248.83 73.2895 250.082 70.0096 248.452 65.5565L257.347 65.3588C256.93 69.3247 258.242 72.3951 261.894 74.2539L260.707 81.1716Z" fill="#FFB9B9"/>
<path d="M244.822 197.058L244.331 203.774C244.308 204.078 244.352 204.383 244.457 204.669C244.563 204.955 244.727 205.216 244.942 205.433C245.156 205.649 245.414 205.818 245.699 205.927C245.984 206.036 246.288 206.082 246.592 206.064L250.042 205.855C250.202 205.845 250.358 205.801 250.498 205.724C250.639 205.647 250.761 205.54 250.855 205.411C250.95 205.282 251.015 205.134 251.046 204.977C251.077 204.819 251.073 204.658 251.034 204.502L249.12 196.843L244.822 197.058Z" fill="#E6E6E6"/>
<path d="M255.99 196.843L254.075 204.502C254.037 204.658 254.033 204.819 254.063 204.977C254.094 205.134 254.159 205.282 254.254 205.411C254.349 205.54 254.47 205.647 254.611 205.724C254.752 205.801 254.907 205.845 255.067 205.855L258.517 206.064C258.821 206.082 259.126 206.036 259.41 205.927C259.695 205.818 259.953 205.649 260.167 205.433C260.382 205.216 260.547 204.955 260.652 204.669C260.758 204.383 260.8 204.078 260.779 203.774L260.287 197.058L255.99 196.843Z" fill="#E6E6E6"/>
<path d="M260.511 197.99L255.855 197.523L251.22 147.982L249.469 197.364L244.895 197.2L237.187 128.808H266.638L260.511 197.99Z" fill="#AF1857"/>
<path d="M253.395 68.7189C258.307 68.7189 262.29 64.7367 262.29 59.8242C262.29 54.9118 258.307 50.9294 253.395 50.9294C248.483 50.9294 244.5 54.9118 244.5 59.8242C244.5 64.7367 248.483 68.7189 253.395 68.7189Z" fill="#FFB9B9"/>
<path d="M270.986 130.39L235.012 130.785C240.35 110.461 243.544 91.5814 237.976 78.6019L246.871 74.2539C251.16 78.7269 255.937 78.266 261.103 73.8582L269.602 76.0322C268.147 93.0528 268.799 111.282 270.986 130.39Z" fill="#AF1857"/>
<path d="M225.129 109.437L247.465 118.333L252.458 119.801C252.682 119.867 252.917 119.887 253.147 119.859C253.379 119.832 253.602 119.758 253.804 119.643C254.006 119.527 254.182 119.372 254.323 119.186C254.464 119.001 254.565 118.789 254.622 118.563C254.721 118.167 254.676 117.749 254.496 117.382C254.316 117.016 254.012 116.724 253.638 116.561L248.453 114.972L232.838 105.88L236.099 97.2813L229.28 93.6243L225.129 109.437Z" fill="#FFB9B9"/>
<path d="M225.326 93.2293C229.576 94.8868 232.956 97.7168 235.605 101.531L243.907 89.4734L237.977 78.6019L225.326 93.2293Z" fill="#AF1857"/>
<path d="M263.419 58.3826C259.809 57.4525 256.259 57.3248 252.809 58.5046C250.611 59.5691 247.972 59.471 245.036 58.594V49.7196L260.883 50.1422L263.419 58.3826Z" fill="#2F2E41"/>
<path d="M247.391 99.0741C246.692 99.0748 246.022 99.3523 245.529 99.8461C245.035 100.34 244.757 101.01 244.756 101.708L245.796 123.476C245.797 124.174 246.075 124.844 246.569 125.337C247.062 125.831 247.732 126.109 248.43 126.11H260.319C261.017 126.109 261.687 125.831 262.181 125.337C262.675 124.844 262.953 124.174 262.954 123.476L263.994 101.708C263.992 101.01 263.715 100.34 263.221 99.8461C262.727 99.3523 262.057 99.0748 261.359 99.0741H247.391Z" fill="#3F3D56"/>
<path d="M281.122 107.793L261.654 115.545L260.184 116.13C260.138 116.148 260.091 116.164 260.042 116.18L255.121 117.628C254.491 117.812 253.621 116.965 253.111 116.38C252.859 116.098 252.716 115.735 252.708 115.357C252.701 114.978 252.829 114.61 253.07 114.318C253.138 114.24 253.213 114.168 253.293 114.103C253.482 113.955 253.706 113.857 253.943 113.816L258.859 112.849C259.035 112.815 259.205 112.751 259.361 112.662L261.654 111.326L273.557 104.395C273.888 104.203 274.14 103.899 274.268 103.538C274.396 103.178 274.392 102.783 274.257 102.425L271.984 96.433C271.845 96.0662 271.844 95.6609 271.981 95.2941C272.118 94.9266 272.385 94.6216 272.73 94.4362L276.495 92.4188C276.71 92.3034 276.948 92.2381 277.192 92.2285C277.436 92.2182 277.679 92.2642 277.903 92.3618C278.127 92.4593 278.325 92.6063 278.484 92.7918C278.642 92.9772 278.757 93.1963 278.819 93.4326L282.087 105.88C282.187 106.262 282.144 106.667 281.966 107.019C281.789 107.372 281.488 107.647 281.122 107.793Z" fill="#FFB9B9"/>
<path d="M282.252 91.0546C278.002 92.7128 274.623 95.5421 271.974 99.3564L263.672 87.2993L269.602 76.0322L282.252 91.0546Z" fill="#AF1857"/>
<path d="M259.314 67.7411C255.422 69.3597 251.342 69.2938 247.095 67.7411C248.331 65.6317 246.484 63.9797 245.535 61.8504L261.393 61.3791C260.181 63.2289 258.391 65.4144 259.314 67.7411Z" fill="white"/>
<path d="M261.225 61.7311L261.041 61.5473L261.301 61.2873L262.855 59.4749L263.052 59.6438L261.492 61.4636L261.225 61.7311Z" fill="white"/>
<path d="M244.448 60.6293L244.282 60.8292L245.842 62.129L246.008 61.9291L244.448 60.6293Z" fill="white"/>
<path d="M261.673 63.2594L258.943 67.5484L259.162 67.688L261.893 63.399L261.673 63.2594Z" fill="white"/>
<path d="M244.736 63.2591L244.517 63.3987L247.246 67.6881L247.465 67.5485L244.736 63.2591Z" fill="white"/>
<path d="M262.173 110.958V115.668L260.534 116.321C260.481 116.341 260.429 116.358 260.374 116.376L254.88 117.992C254.178 118.199 253.207 117.252 252.638 116.599C252.356 116.285 252.197 115.88 252.188 115.457C252.18 115.035 252.322 114.624 252.591 114.298C252.667 114.21 252.75 114.13 252.84 114.057C253.053 113.892 253.302 113.783 253.566 113.738L259.053 112.659C259.251 112.62 259.439 112.55 259.614 112.45L262.173 110.958Z" fill="white"/>
<path d="M264.549 94.1593H263.609C263.609 80.8728 260.874 71.6134 258.208 71.6134L257.973 70.6738C260.364 70.6738 261.888 74.5272 262.746 77.7604C263.909 82.1435 264.549 87.967 264.549 94.1593Z" fill="#3F3D56"/>
<path d="M242.943 90.8712H242.003C242.003 78.7029 244.758 71.1436 248.814 71.1436L248.579 72.0826C245.773 72.0826 242.943 77.8923 242.943 90.8712Z" fill="#3F3D56"/>
<path d="M264.08 96.7428C265.377 96.7428 266.428 95.6911 266.428 94.3943C266.428 93.0974 265.377 92.0458 264.08 92.0458C262.783 92.0458 261.731 93.0974 261.731 94.3943C261.731 95.6911 262.783 96.7428 264.08 96.7428Z" fill="#3F3D56"/>
<path d="M239.184 96.5078H238.245C238.245 93.1414 240.142 90.402 242.472 90.402V91.341C240.66 91.341 239.184 93.6593 239.184 96.5078Z" fill="#3F3D56"/>
<path d="M246.7 96.5078H245.761C245.761 93.6593 244.286 91.341 242.473 91.341V90.402C244.804 90.402 246.7 93.1414 246.7 96.5078Z" fill="#3F3D56"/>
<path d="M246.231 97.6824C246.75 97.6824 247.171 97.2621 247.171 96.7428C247.171 96.2241 246.75 95.8038 246.231 95.8038C245.712 95.8038 245.292 96.2241 245.292 96.7428C245.292 97.2621 245.712 97.6824 246.231 97.6824Z" fill="#3F3D56"/>
<path d="M238.715 97.6824C239.234 97.6824 239.654 97.2621 239.654 96.7428C239.654 96.2241 239.234 95.8038 238.715 95.8038C238.196 95.8038 237.775 96.2241 237.775 96.7428C237.775 97.2621 238.196 97.6824 238.715 97.6824Z" fill="#3F3D56"/>
<path d="M203.645 136.732H10.7979C10.3018 136.731 9.82618 136.534 9.47538 136.183C9.12458 135.832 8.92731 135.356 8.92676 134.861V20.8484C8.92731 20.3524 9.12458 19.8768 9.47538 19.526C9.82618 19.1752 10.3018 18.9779 10.7979 18.9773H203.645C204.141 18.9779 204.616 19.1752 204.967 19.526C205.318 19.8768 205.516 20.3524 205.516 20.8484V134.861C205.516 135.356 205.318 135.832 204.967 136.183C204.616 136.534 204.141 136.731 203.645 136.732ZM10.7979 19.7258C10.5002 19.7261 10.2148 19.8445 10.0044 20.0549C9.79389 20.2654 9.67554 20.5508 9.6752 20.8484V134.861C9.67554 135.158 9.79389 135.443 10.0044 135.654C10.2148 135.864 10.5002 135.983 10.7979 135.983H203.645C203.943 135.983 204.228 135.864 204.438 135.654C204.649 135.443 204.767 135.158 204.767 134.861V20.8484C204.767 20.5508 204.649 20.2654 204.438 20.0549C204.228 19.8445 203.943 19.7261 203.645 19.7258H10.7979Z" fill="#3F3D56"/>
<path d="M88.6328 28.0829C88.2433 28.0895 87.8724 28.2486 87.5997 28.5261C87.327 28.8036 87.1738 29.1772 87.1738 29.5664C87.1738 29.9556 87.327 30.3292 87.5997 30.6067C87.8724 30.8841 88.2433 31.0432 88.6328 31.0498H125.827C126.219 31.0555 126.598 30.9055 126.88 30.6323C127.162 30.3593 127.325 29.9854 127.332 29.5929C127.339 29.2002 127.189 28.8209 126.917 28.5379C126.645 28.2548 126.272 28.0913 125.88 28.083L125.875 28.0829C125.859 28.0827 125.842 28.0827 125.827 28.0829H88.6328Z" fill="#3F3D56"/>
<path d="M50.2159 43.271H24.5195V60.7346H50.2159V43.271Z" fill="#E5E5E5"/>
<path d="M85.1424 43.271H59.4463V60.7346H85.1424V43.271Z" fill="#E5E5E5"/>
<path d="M120.07 43.271H94.374V60.7346H120.07V43.271Z" fill="#E5E5E5"/>
<path d="M154.996 43.271H129.3V60.7346H154.996V43.271Z" fill="#E5E5E5"/>
<path d="M189.924 43.271H164.228V60.7346H189.924V43.271Z" fill="#E5E5E5"/>
<path d="M50.3096 73.9571H24.6133V91.4207H50.3096V73.9571Z" fill="#E5E5E5"/>
<path d="M85.2362 73.9571H59.54V91.4207H85.2362V73.9571Z" fill="#E5E5E5"/>
<path d="M120.163 73.9571H94.4668V91.4207H120.163V73.9571Z" fill="#E5E5E5"/>
<path d="M155.09 73.9571H129.395V91.4207H155.09V73.9571Z" fill="#E5E5E5"/>
<path d="M190.018 73.9571H164.322V91.4207H190.018V73.9571Z" fill="#E5E5E5"/>
<path d="M50.4043 104.643H24.708V122.106H50.4043V104.643Z" fill="#E5E5E5"/>
<path d="M85.3312 104.643H59.6348V122.106H85.3312V104.643Z" fill="#E5E5E5"/>
<path d="M120.258 104.643H94.5615V122.106H120.258V104.643Z" fill="#E5E5E5"/>
<path d="M155.185 104.643H129.489V122.106H155.185V104.643Z" fill="#E5E5E5"/>
<path d="M190.112 104.643H164.415V122.106H190.112V104.643Z" fill="#E5E5E5"/>
<path d="M99.7067 52.0028C101.498 52.0028 102.95 50.5507 102.95 48.7595C102.95 46.9683 101.498 45.5163 99.7067 45.5163C97.916 45.5163 96.4639 46.9683 96.4639 48.7595C96.4639 50.5507 97.916 52.0028 99.7067 52.0028Z" fill="#AF1857"/>
<path d="M64.2803 82.6889C66.0715 82.6889 67.5235 81.2368 67.5235 79.4454C67.5235 77.654 66.0715 76.2019 64.2803 76.2019C62.4891 76.2019 61.0371 77.654 61.0371 79.4454C61.0371 81.2368 62.4891 82.6889 64.2803 82.6889Z" fill="#AF1857"/>
<path d="M99.7067 113.375C101.498 113.375 102.95 111.922 102.95 110.132C102.95 108.34 101.498 106.888 99.7067 106.888C97.916 106.888 96.4639 108.34 96.4639 110.132C96.4639 111.922 97.916 113.375 99.7067 113.375Z" fill="#AF1857"/>
<path d="M135.382 82.6889C137.173 82.6889 138.625 81.2368 138.625 79.4454C138.625 77.654 137.173 76.2019 135.382 76.2019C133.591 76.2019 132.139 77.654 132.139 79.4454C132.139 81.2368 133.591 82.6889 135.382 82.6889Z" fill="#3F3D56"/>
<path d="M170.06 82.6889C171.851 82.6889 173.303 81.2368 173.303 79.4454C173.303 77.654 171.851 76.2019 170.06 76.2019C168.269 76.2019 166.816 77.654 166.816 79.4454C166.816 81.2368 168.269 82.6889 170.06 82.6889Z" fill="#3F3D56"/>
<path d="M64.2803 113.375C66.0715 113.375 67.5235 111.922 67.5235 110.132C67.5235 108.34 66.0715 106.888 64.2803 106.888C62.4891 106.888 61.0371 108.34 61.0371 110.132C61.0371 111.922 62.4891 113.375 64.2803 113.375Z" fill="#3F3D56"/>
</g>
<defs>
<clipPath id="clip0_7_622">
<rect width="283" height="206.068" fill="white"/>
</clipPath>
</defs>
</svg>  