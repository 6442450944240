import React, { Component } from 'react'
import { Row, Col, Typography } from "antd";
import { Link } from "react-router-dom";
import i18n from '../../contexts/I18n';

const { Title } = Typography;
export default class HeadingTable extends Component {
    translate = i18n;
    render() {
        return (
            <Row>
                <Col span={24} xl={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Title level={5} style={{ fontSize: '18px', opacity: '85%' }}>
                        {this.props.title}
                    </Title></Col>
                <Col span={24} xl={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}><Link className="read-more link-custom" to={this.props.link}>
                    <span className="text">{this.props.linkName} {'\u2192'}</span>
                </Link></Col>
            </Row>
        )
    }
}




