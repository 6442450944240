import Documenti from '../pages/Documenti/Documenti';
import Faq from '../pages/Faq/Faq';
import Home from '../pages/Home/Home';
import Percorsi from '../pages/Percorsi/Percorsi';
import VisiteProgrammate from '../pages/VisiteProgrammate/VisiteProgrammate';

export const globalRoutes = [
    {
        key: 'home',
        name: 'home',
        path: "/",
        page: Home
    },
    {
        key: 'home',
        name: 'home',
        path: "/home",
        page: Home
    },
    {
        key: 'visite-programmate',
        name: 'Visite programmate',
        path: "/visite-programmate",
        page: VisiteProgrammate,
        breadcrumb: [{ label: 'VISITE PROGRAMMATE' }]
    },
    {
        key: 'documenti',
        name: 'Documenti',
        path: "/documenti",
        page: Documenti,
        breadcrumb: [{ label: 'DOCUMENTI' }]
    },
    {
        key: 'percorsi',
        name: 'Percorsi',
        path: "/percorsi",
        page: Percorsi,
        breadcrumb: [{ label: 'PERCORSI' }]
    },
    {
        key: 'faq',
        name: 'Faq',
        path: "/faq",
        page: Faq,
        breadcrumb: [{ label: 'FAQ - DOMANDE E RISPOSTE' }]
    }
];

export const globalMenuRoutes = [
    {
        type: "flat",
        key: 'home',
        name: "Home",
        path: "/home",
        page: Home
    },
    {
        type: "flat",
        key: 'visite-programmate',
        name: "Visite programmate",
        path: "/visite-programmate",
        page: VisiteProgrammate
    },
    // {
    //     type: "flat",
    //     key: 'documenti',
    //     name: 'Documenti',
    //     path: "/documenti",
    //     page: Documenti
    // },
    {
        type: "flat",
        key: 'percorsi',
        name: 'Percorsi',
        path: "/percorsi",
        page: Percorsi
    },
    {
        type: "flat",
        key: 'faq',
        name: 'FAQ',
        path: "/faq",
        page: Faq,
    }
];













