import { Carousel, Typography, Image } from 'antd'
import React, { Component } from 'react'
import { withRouter } from 'react-router';
import i18n from '../../contexts/I18n';

const { Text, Title, Paragraph } = Typography;
class CarouselHomePage extends Component {
    translate = i18n;

    render() {
        return (
            this.props.percorsi?.list?.length > 0 &&
            <Carousel className="carousel-percorsi" autoplay={true} autoplaySpeed={5000}>
                {this.props.percorsi.list.map(item => {
                    return <div className="carousel-item" key={item.idPercorso}>
                        <div className="description-section">
                            <Title ellipsis={{
                                rows: 2,
                                symbol: '...',
                            }}
                                level={3}>
                                {item.title}
                            </Title>
                            <Paragraph
                                ellipsis={{
                                    rows: 14,
                                    symbol: '...',
                                }}
                                title={item.title}
                            >
                                {item.description}
                            </Paragraph>
                            <Text onClick={() => {
                                this.props.history.push({ pathname: "/percorsi", idPercorso: item.idPercorso })
                            }} italic underline style={{ cursor: 'pointer' }}>
                                {this.translate.t("consultori.carosello.scopri.piu")}
                            </Text>
                        </div>
                        {
                            <Image height='450px' width='100%' preview={false} src={"data:image/png;base64," + item.image} />
                        }
                    </div>
                })
                }
            </Carousel>
        )
    }
}

export default withRouter(CarouselHomePage)