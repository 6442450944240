import Icon from '@ant-design/icons';
import { Badge, Col, Collapse, Empty, Pagination, Row, Space, Typography } from 'antd';
import React, { Component } from 'react';

import { CollapseDown } from '../../assets/icons/CollapseDownIcon';
import { CollapseUp } from '../../assets/icons/CollapseUpIcon';
import { PageNextDisabled } from '../../assets/icons/PageNextDisabledIcon';
import { PageNext } from '../../assets/icons/PageNextIcon';
import { PagePrevDisabled } from '../../assets/icons/PagePrevDisabledIcon';
import { PagePrev } from '../../assets/icons/PagePrevIcon';
import HeroBanner from '../../components/HeroBanner/HeroBanner';
import i18n from '../../contexts/I18n';
import { getFaq } from '../../service/service';

const { Text, Title } = Typography;
const { Panel } = Collapse;
class Faq extends Component {
    translate = i18n;
    constructor(props) {
        super(props)

        this.state = {
            faq: [],
            faqPage: [],
            currentPage: 1,
            faqLoading: true,
            totalElement: 0
        }
    }

    getFaqCall = (page) => {
        getFaq(page || 0, 10)
            .then(result => {
                const faq = result.data.sort((a, b) => {
                    return a.ordine - b.ordine;
                });
                this.setState({ faq: faq, faqPage: faq.slice(0, 10), faqLoading: false, totalElement: faq.length });
            })
            .catch(error => this.setState({ faqLoading: false }))
    }

    componentDidMount() {
        this.getFaqCall();
    }

    render() {
        const getExpandIcon = (panelProps) => {
            if (panelProps.isActive)
                return <Icon component={CollapseUp} />
            else
                return <Icon component={CollapseDown} />
        }
        const itemRender = (current, type, originalElement) => {
            switch (type) {
                case 'prev':
                    if (this.state.currentPage === 1)
                        return <Icon component={PagePrevDisabled} />;
                    else
                        return <Icon component={PagePrev} />;
                case 'next':
                    if (this.state.currentPage === parseInt(this.state.totalElement / 10) + 1)
                        return <Icon component={PageNextDisabled} />
                    else
                        return <Icon component={PageNext} />
                case 'page':
                    return originalElement;
                default:
                    break;
            }
        }

        const showMessage = () => {
            return !this.state.totalElement > 0
        }

        return (
            <>
                <HeroBanner isHomePage={false}
                    title={
                        this.translate.t("consultori.faq.title")
                    }
                    description={
                        <>
                            <Text>{this.translate.t("consultori.faq.description")}</Text>
                        </>
                    } />
                <Row>
                    <Col offset={1} style={{ paddingTop: 40 }}>
                        <Space
                            size='large'
                            align='start'
                        >
                            <Title level={3}>{this.translate.t("consultori.faq.list.title")}</Title>
                            <Badge
                                count={this.state.totalElement}
                                style={{
                                    width: 40,
                                    height: 24,
                                    borderRadius: 12,
                                    backgroundColor: '#AF1857'
                                }}
                            />
                        </Space>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={22}>
                        {this.state.faq?.map((item) => (
                            <Row key={item.ordine} style={{ marginBottom: 20 }}>
                                <Col span={24}>
                                    <Collapse

                                        ghost
                                        accordion={true}
                                        expandIconPosition='right'
                                        className='custom-collapse-header'
                                        expandIcon={getExpandIcon}
                                        style={{
                                            background: '#FFFFFF',
                                            boxShadow: '-1px 7px 8px 0px rgba(0, 0, 0, 0.1)',
                                            borderRadius: 6,
                                            minHeight: 100
                                        }}

                                    >
                                        <Panel

                                            header={
                                                <>
                                                    <Text strong style={{ fontSize: 18, color: '#17324D' }}>
                                                        {item.domanda}
                                                    </Text>
                                                </>
                                            }
                                            style={{ textAlign: 'left' }}
                                        >
                                            <Text style={{ fontSize: 16, color: '#5B5B5B' }}>
                                                {item.risposta}
                                            </Text>
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </Row>))}
                    </Col>

                </Row>
                <Row justify="center">
                    <Col span={4} style={{ marginBottom: 40, marginTop: 40 }}>
                        <Pagination
                            simple
                            hideOnSinglePage={true}
                            current={this.state.currentPage}
                            total={this.state.totalElement}
                            onChange={(page, pageSize) => {
                                const realPage = page - 1;
                                this.getFaqCall(realPage)

                            }}
                            itemRender={itemRender}
                        />
                    </Col>
                </Row>
                <Row justify="center" style={{ display: showMessage() ? 'block' : 'none' }}>
                    <Empty />
                </Row>
            </>
        )
    }
}

export default Faq













