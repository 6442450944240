import React, { Component } from 'react'

import { Row, Col, Typography, Space, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import HeroBanner from "../../components/HeroBanner/HeroBanner";
import CTable from "../../components/Tables/CTable";
import CardBox from '../../components/Cards/CardBox/CardBox';
import SearchFilterForm from '../../components/Forms/SearchFIlterForm/SearchFilterForm';
import i18n from '../../contexts/I18n';
import { downloadDocument, getDocumenti } from '../../service/service';

const { Text } = Typography;
export default class Documenti extends Component {
    translate = i18n;
    constructor(props) {
        super(props)

        this.state = {
            documenti: {},
            loadingTableDocumenti: false,
            currentPage: 0,
            currentSearch: {},
            loadingDownload: {}
        }
    }

    changePage = (page) => {
        this.setState({ currentPage: page }, () => this.getDocumentiUtente());
    }

    search = (search) => {
        this.setState({ currentSearch: search, currentPage: 0 }, () => this.getDocumentiUtente());
    }

    getDocumentiUtente = () => {
        this.setState({
            loadingTableDocumenti: true,
        });

        getDocumenti(this.state.currentSearch.reparto !== this.translate.t("consultori.form.filtri.reparto") ? this.state.currentSearch.reparto : "", this.state.currentSearch.search, 5, this.state.currentPage)
            .then((response) => {
                this.setState({
                    documenti: response.data,
                    loadingTableDocumenti: false,
                });
            })
            .catch((error) => {
                this.setState({
                    loadingTableDocumenti: false,
                });
                if (error.response?.status) {
                    Notification(
                        this.translate.t("consultori.errors.visite"),
                        null,
                        null,
                        "error"
                    );
                }
            });
    };

    downloadPdf = (id, nome, index) => {
        let blob;
        let pdf;
        this.setState({
            loadingDownload: {
                [index]: true
            }
        })

        downloadDocument(id).then(result => {
            blob = new Blob([result.data], { type: 'application/pdf' });
            pdf = URL.createObjectURL(blob);
            this.setState({
                loadingDownload: {
                    [index]: false
                }
            })
            //window.open(pdf)
            const link = document.createElement("a");
            link.href = pdf;
            let fileName = this.translate.t('consultori.fileNames.documento') + nome;
            const headerDisposition = result.headers["content-disposition"];
            if (headerDisposition) {
                fileName = headerDisposition.split("filename=")[1] && headerDisposition.split("filename=")[1] !== "null" ? headerDisposition.split("filename=")[1] : fileName;
            }
            link.setAttribute("download", fileName);
            link.click();
        }).catch(error => {
            this.setState({
                loadingDownload: {
                    ...this.state.loadingDownload,
                    [index]: false
                }
            })
            Notification(this.translate.t('consultori.errors.pdf'), null, null, "error")
        })
    }

    componentDidMount() {
        this.getDocumentiUtente();
    }

    render() {
        const columnsDocumenti = [
            {
                title: this.translate.t("consultori.table.documenti.colonna.reparto"),
                dataIndex: "department",
                key: "department",
                render: text => <Text title={text}>{text}</Text>
            },
            {
                title: this.translate.t("consultori.table.documenti.colonna.nome"),
                dataIndex: "name",
                key: "name",
                width: "35%",
                render: text => <Text title={text}>{text}</Text>
            },
            {
                title: this.translate.t("consultori.table.documenti.colonna.medico"),
                dataIndex: "doctor",
                key: "doctor",
                render: text => <Text title={text}>{text}</Text>
            },
            {
                title: this.translate.t("consultori.table.documenti.colonna.data"),
                dataIndex: "data",
                key: "data",
                render: text => <Text title={text}>{text}</Text>
            },
            {
                title: this.translate.t("consultori.table.documenti.colonna.azioni"),
                align: "center",
                className: "col-azioni",
                render: (text, record, index) => (
                    <Space direction="vertical" size={0}>
                        <Button
                            className="btn-custom"
                            danger
                            icon={<>{this.translate.t("consultori.table.documenti.scarica")} <DownloadOutlined /></>}
                            onClick={() =>
                                this.downloadPdf(record?.id, record?.name, index)
                            }
                            //loading={this.state.loadingDownload[index]}
                            style={{ fontSize: '14px', width: '75px', padding: '5px' }}
                        />
                    </Space>
                ),
            },
        ];

        return (
            <>
                <HeroBanner
                    isHomePage={false}
                    title={
                        this.translate.t("consultori.documenti.title")
                    }
                    description={
                        <>
                            <Text>{this.translate.t("consultori.documenti.description")}</Text>
                        </>
                    }
                />


                <Row justify="center">
                    <Col span={24}>
                        <Row justify="center">
                            <Col span={22}>
                                <CardBox>
                                    <SearchFilterForm search={this.search.bind(this)} />
                                </CardBox>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={22}>
                                <CTable
                                    //loading={this.state.loadingTableDocumenti}
                                    lista={this.state.documenti?.list}
                                    columns={columnsDocumenti}
                                    emptyMessage={this.translate.t("consultori.table.documenti.empty")}
                                    showPagination={true}
                                    pageSize={5}
                                    totalElement={this.state.documenti?.totaleElementi}
                                    changePage={this.changePage.bind(this)}
                                    currentPage={this.state.currentPage + 1}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}
