import React, { Component } from 'react';
import './CardBox.scss';
import { Row, Col, Typography, Space, Card } from 'antd';
import i18n from '../../../contexts/I18n';
const { Text } = Typography;
class CardBox extends Component {
    translate = i18n;

    render() {
        return (
            <Card bordered={false} className="box-search">
                <Row justify="start" gutter={[0, 20]}>
                    {this.props.children}
                </Row>
            </Card>
        )
    }
}
export default CardBox

