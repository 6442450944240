import axios from 'axios';

import { Constants } from '../constants/Constants';
import { gStore } from '../store';

const getHeaders = () => {
    return {
        "Authorization": `Bearer ${gStore.get('portal_session')?.token || Constants.DEV_TOKEN}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
        "correlationId": gStore.get("correlationId") ? gStore.get("correlationId") : "",
    }
};

const getHeadersPdf = () => {
    return {
        "Authorization": `Bearer ${gStore.get('portal_session')?.token || Constants.DEV_TOKEN}`,
        "Content-Type": "application/pdf",
        "Accept": "application/json",
        "correlationId": gStore.get("correlationId") ? gStore.get("correlationId") : "",
    }
};

export const getVisite = (ricerca, num_elementi, pagina) => axios.get(Constants.API_URL?.concat('/visite'), { params: { ricerca, num_elementi, pagina }, headers: getHeaders() });

export const getDocumenti = (id_reparto, ricerca, num_elementi, pagina) => axios.get(Constants.API_URL?.concat('/documenti'), { params: { id_reparto, ricerca, num_elementi, pagina }, headers: getHeaders() });

export const downloadDocument = (id_documento) => axios.get(Constants.API_URL?.concat('/documenti').concat("/").concat(id_documento).concat("/download"), { headers: getHeadersPdf(), responseType: 'blob' });

export const getReparti = () => axios.get(Constants.API_URL?.concat('/reparti'), { headers: getHeaders() });

export const getPercorsi = (num_elementi, pagina) => axios.get(Constants.API_URL?.concat("/percorsi"), { headers: getHeaders(), params: { num_elementi, pagina } });

export const getPercorsiSommario = (num_elementi, pagina) => axios.get(Constants.API_URL?.concat("/percorsi/sommario"), { headers: getHeaders(), params: { num_elementi, pagina } });

export const getPercorsoDettaglio = (idPercorso) => axios.get(Constants.API_URL?.concat("/percorsi/" + idPercorso), { headers: getHeaders() });

export const getConfiguration = () => axios.get(Constants.API_URL?.concat("/configs"), { headers: getHeaders() });

export const getFaq = (pagina, num_elementi) => axios.get(Constants.FAQ_BASE_URL, { headers: getHeaders() });

export const getDatiAnagrafici = () => axios.get(
    Constants.API_ANAGRAFICA_URL?.concat('/anagrafica/me'), { headers: getHeaders() }
);

export const getDatiAnagraficiAssistito = (codiceFiscaleAssistito) => axios.get(
    Constants.API_ANAGRAFICA_URL?.concat('/anagrafica/me/assistito'), { params: { codiceFiscaleAssistito }, headers: getHeaders() }
);
